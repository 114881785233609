<!--用户使用充值码卡密记录-->
<template>
    <div style="margin-bottom: 10px;">
        <el-button @click="this.$router.go(-1)" :disabled="isLoading">{{lang['html.el-button.go.-1']}}</el-button>
        <el-button @click="this.$router.go(0)" style="margin-left: 10px" :disabled="isLoading">{{lang['html.el-button.go.0']}}</el-button>
        <el-tag style="margin-left: 10px">{{lang['html.el-tag.user']}}:{{this.username}}</el-tag>
    </div>

    <el-table
        stripe
        border
        v-loading=isLoading
        element-loading-text="Loading..."
        :data="tableDataAll"
    >
        <el-table-column :label="lang['html.el-table-column.label.id']"  width="200"  highlight-current-row>
            <template #default="scope">
                {{ this.$helper.fixZeroStart(scope.row.id,9)}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.key']" highlight-current-row>
            <template #default="scope">
                {{ scope.row.key}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.money']"  highlight-current-row>
            <template #default="scope">
                {{ scope.row.money}}
            </template>
        </el-table-column>

        <el-table-column :label="lang['html.el-table-column.label.time']" highlight-current-row>
            <template #default="scope">
                {{ scope.row.time}}
            </template>
        </el-table-column>

    </el-table>
</template>

<script>
export default {
    name: "userUseRecKey",
    data(){
        return {
            tableDataAll:[],
            username:'',
            isLoading:true,
            lang:this.$lang.getLang('user','userUseRecKey'),
        }
    },
    methods:{
        getUseKeyData(){
            this.$notify({message:this.lang['getUseKeyData.wait']})
            this.isLoading = true;
            this.$api.post('Key.KeyData/getUserUseRecKey',{username:this.username}).then(res=>{
                let data = this.$helper.checkRes(res,true);
                if (!data){
                    return;
                }
                let list = data.data ?? null;
                if (!list){
                    this.$alert(this.lang['getUseKeyData.error.res']);
                    return;
                }
                if (list.length <= 0){
                    this.$alert(this.lang['getUseKeyData.error.length']);
                    return;
                }
                this.updateTableData(list);
            })
                .catch(err=>this.$helper.axiosCatch(err))
                .finally(()=>this.isLoading = false);
        },
        updateTableData(data){
            this.tableDataAll = data;
        }
    },
    mounted() {
        this.username = this.$route.params.username;
        this.getUseKeyData();
    }
}
</script>